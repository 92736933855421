<template>
  <b-modal
    id="overseas-quote-view-modal"
    title="Overseas Quote"
    centered
    hide-footer
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <div class="container" style="margin-bottom: 30px">
      <div style="margin-bottom: 30px">
        <b-row>
          <b-col md="8" v-if="overseasQuote">
            <div class="">
              <span>
                <h5>
                  <span style="font-weight: bold">Name:</span>
                  {{ overseasQuote.full_name }}
                </h5>
              </span>
              <span>
                <h5>
                  <span style="font-weight: bold">Email:</span>
                  {{ overseasQuote.email }}
                </h5>

                <h5>
                  <span style="font-weight: bold">Mobile:</span>
                  {{ overseasQuote.contact_number }}
                </h5>
                <h5>
                  <span style="font-weight: bold">Gender:</span>
                  {{ overseasQuote.gender }}
                </h5>
                <h5>
                  <span style="font-weight: bold">Passport Number:</span>
                  {{ overseasQuote.passport_number }}
                </h5>
                <h5>
                  <span style="font-weight: bold">NICOP/ NIC:</span>
                  {{ overseasQuote.cnic }}
                </h5>
                <h5>
                  <span style="font-weight: bold">Postal Address:</span>
                  {{ overseasQuote.address }}
                </h5>
              </span>

              <h5 style="font-weight: bold">
                Message:
                <span class="font-weight-normal" v-if="overseasQuote">
                  {{ overseasQuote.message }}
                </span>
              </h5>
            </div>
          </b-col>
          <b-col
            md="4"
            v-if="overseasQuote && overseasQuote.referral_company_name != null"
          >
            <div>
              <h3 style="font-weight: bolder">Referred BY:</h3>
              <h5 style="font-weight: bolder">
                Name:
                <span style="font-weight: normal">{{
                  overseasQuote.referral_company_name
                }}</span>
              </h5>
              <h5 style="font-weight: bolder">
                Reference Number:
                <span style="font-weight: normal">{{
                  overseasQuote.referral_company_code
                }}</span>
              </h5>
              <h5 style="font-weight: bolder">
                Company Name:
                <span class="font-weight-normal">{{
                  overseasQuote.referral_company_company_name
                }}</span>
              </h5>
            </div>
          </b-col>
          <b-col
            md="4"
            v-if="overseasQuote && overseasQuote.referral_company_name === null"
          >
            <div>
              <h4 style="font-weight: bolder">
                Not Referred By Any Referrel Company.
              </h4>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col v-if="overseasQuote && overseasQuote.is_emergency === false">
            <h2 class="text-center font-weight-bolder text-colorBlue">
              Is not an emergecy Case
            </h2>
          </b-col>
          <b-col v-if="overseasQuote && overseasQuote.is_emergency === true">
            <h2 class="text-center font-weight-bolder text-colorBlue">
              Is an emergecy Case
            </h2>
            <span>
              <h5>
                <span style="font-weight: bold">Destination City:</span>
                {{ overseasQuote.destination_city }}
              </h5>
            </span>
            <span>
              <h5>
                <span style="font-weight: bold">emergency Type:</span>
                {{ overseasQuote.emergency_type }}
              </h5>
            </span>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import util from "@/util.js";

export default {
  props: {
    showModal: Boolean,
    overseasQuote: Object,
  },

  data() {
    return {
      // procedures : [],
      // fieldsProcedures: [
      // { key: "name", label: "Name" },
      //   { key: "amount",label: "Starting From"},
      //   { key: "manage", label: "" },
      // ],
    };
  },
  mixins: [util],

  methods: {
    ...mapActions({}),
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
  height: 100%;
}
.flex-container span {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
}
</style>
