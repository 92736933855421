<template>
  <div>
    <b-card>
      <!-- Filters Below  -->
      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Name"
              label-for="name"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-form-input id="name" v-model="name" placeholder="Name" />
            </b-form-group>

            <b-form-group
              label="IsEmergency"
              label-for="isEmergency"
              class="w-50"
              v-if="searchType.value === 2"
            >
              <b-form-select
                id="isEmergency"
                v-model="isEmergency"
                :options="isEmergencyOptions"
                placeholder="Select Type"
              ></b-form-select>
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- Filters above  -->
      <b-table
        responsive="sm"
        :fields="fields"
        :items="quotes"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('read_overseas_pakistani')"
        :per-page="0"
      >
        <template #cell(name)="row">
          {{ truncate(row.item.name, 30) }}
        </template>
        <template #cell(serial)="row">
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </template>

        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="openOverseasQuoteModal(row.item)"
            v-if="hasPermission('read_overseas_pakistani')"
          >
            Details
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>

      <h5 style="font-weight: bold">Total Rows: {{ totalItems }}</h5>
    </b-card>

    <overseas-quote-view-modal
      :overseasQuote="selectedOverseasQuote"
      @modalClosed="closeQuoteModal"
      :key="`view-${overseasQuoteViewModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OverseasQuoteViewModal from "@/components/admin/overseasQuote/QuoteViewModal.vue";
import util from "@/util.js";

export default {
  components: {
    OverseasQuoteViewModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "full_name", label: "NAME" },
        { key: "email", label: "EMAIL" },
        { key: "contact_number", label: "CONTACT" },
        { key: "passport_number", label: "PASSPORT" },
        { key: "created_at", label: "CREATED AT" },
        { key: "updated_at", label: "UPDATED AT" },
        { key: "manage", label: "ACTION" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      quotes: [],
      overseasQuoteViewModalCount: 0,
      searchTypes: [
        { value: 1, name: "Name" },
        { value: 2, name: "Is Emergency" },
      ],
      searchType: null,
      name: "",
      isEmergency: "",
      isEmergencyOptions: [
        { value: "True", text: "Emergency Cases" },
        { value: "False", text: "Normal Cases" },
      ],
      selectedOverseasQuote: null,
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
  methods: {
    ...mapActions({
      getOverseasQuote: "appData/getOverseasQuote",
    }),
    async search() {
      if (this.searchType) {
        switch (this.searchType.value) {
          case 1:
            this.isEmergency = null;
            break;
          case 2:
            this.name = "";
            break;
        }
      } else {
        this.name = "";
        this.isEmergency = null;
      }
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },

    openOverseasQuoteModal(quote) {
      this.overseasQuoteViewModalCount += 1;
      this.selectedOverseasQuote = quote;
      this.$nextTick(() => {
        this.$bvModal.show("overseas-quote-view-modal");
      });
    },

    async fetchPaginatedData() {
      try {
        const res = await this.getOverseasQuote({
          pageNumber: this.currentPage,
          name: this.name ? this.name : "",
          isEmergency: this.isEmergency ? this.isEmergency : null,
        });
        if (res) {
          this.quotes = res.data.results;
          console.log(this.quotes);
          this.totalItems = res.data.count;
          this.perPage = res.data.per_page;
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    closeQuoteModal() {
      this.overseasQuoteViewModalCount = 0;
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    rows() {
      return this.quotes.length;
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
    searchType: function (newVal, oldVal) {
      if (newVal && oldVal && newVal.value !== oldVal.value) {
        this.name = "";
        this.email = "";
      }
    },
  },
};
</script>
